<template>
  <div class="deckupload">
    <Modal
      size="2xs"
      :show-modal="expanded"
      @close-modal="closeDialog"
    >
      <template #body>
        <div class="flex flex-col gap-2">
          <FormDragAndDropContainer
            :allowed-file-types="acceptedFileTypes"
            :disabled="formStatus !== 'idle'"
            @update="handleFileUpload"
          >
            <div v-auto-animate>
              <div
                v-if="formStatus === 'idle'"
                class="flex flex-col gap-4"
              >
                <h2 class="text-2xl font-semibold text-gray-90">
                  Submit Deals
                </h2>
                <div
                  v-show="fileSizeFeedBack.length"
                  class="p-2 text-sm border rounded-lg border-error-700 text-error-700"
                >
                  <p class="font-semibold">
                    The following files are too large:
                  </p>
                  <ul class="list-disc list-inside">
                    <li
                      v-for="file in fileSizeFeedBack"
                      :key="file"
                    >
                      {{ file }}
                    </li>
                  </ul>
                </div>
                <FormFileUpload
                  :accepted-file-types="acceptedFileTypes"
                  @update="handleFileUpload"
                />
                <div class="pb-8">
                  <FormKit
                    id="myForm"
                    type="form"
                    :actions="false"
                    :config="{ validationVisibility: 'submit' }"
                    @submit="handleAddUrl"
                  >
                    <legend
                      class="flex items-center gap-2 pb-2 pl-2 text-sm font-semibold text-gray-900"
                    >
                      <span>Add a link</span>
                      <tippy content="We support: website url, docsend, pitch.com, google drive, google docs and hubspot">
                        <span class="inline-block w-5 ">
                          <IconResolver icon-name="question" />
                        </span>
                      </tippy>
                    </legend>
                    <div class="flex items-start justify-start px-1">
                      <div class="flex-1">
                        <FormKit
                          id="url_to_be_added"
                          outer-class=""
                          wrapper-class="$reset"
                          inner-class=" ring-1 formkit-inner ring-gray-400 border-none flex items-center max-w-md focus-within:ring-primary-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-primary-500 rounded rounded-r-none mb-1 h-[36px] focus-within:z-10 relative bg-white"
                          label-class="sr-only"
                          type="text"
                          name="url_to_be_added"
                          label="Company URL or DocSend link"
                          :validation-messages="{
                            required: 'This field can not be empty',
                            validTLD:
                              'Please enter a url with a valid domain. Eg. .com',
                          }"
                          validation="required"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          class="rounded-r-md py-2 px-2.5 ring-1 text-sm ring-gray-400 bg-gray-100 flex gap-2 items-center hover:bg-gray-200 transition-colors duration-150 outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-1 focus-visible:ring-offset-gray-100 focus-visible:z-10 relative"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </FormKit>
                </div>
              </div>
              <UploadResults
                v-else-if="formStatus === 'done'"
                :parsed-items="parsedItems"
                :failed-items="failedItems"
              />

              <div
                v-if="queuedForUpload.length"
                class="flex flex-col gap-2"
              >
                <h3 class="text-sm font-semibold">
                  Queued for processing
                </h3>
                <div class="">
                  <div
                    v-for="item in queuedForUpload"
                    :key="item.type === 'file' ? item.file.name : item.url"
                    class="mb-2"
                  >
                    <DeckUploadPreview
                      :text="item.type === 'file' ? item.file.name : item.url"
                      :type="item.type"
                      :status="item.status"
                      :is-submitting="formStatus === 'working'"
                      @remove="removeItemFromQueue"
                    />
                    <!-- <p v-if="item.status === 'failed' && item.statusMessage">
                      {{ item.statusMessage }}
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </FormDragAndDropContainer>

          <div v-auto-animate>
            <div class="grid w-full grid-cols-2 gap-2 pt-4">
              <FormKit
                outer-class="flex flex-col items-stretch w-full text-center"
                :full-width="true"
                type="button"
                variant="secondaryGray"
                size="sm"
                :label="'Close'"
                @click="closeDialog"
              />
              <FormKit
                id="submit_deals"
                type="button"
                outer-class="flex flex-col items-stretch w-full text-center data-[disabled=true]:opacity-[100%_!important]"
                :full-width="true"
                variant="primary"
                size="sm"
                :label="
                  formStatus === 'done'
                    ? 'Submit more'
                    : generatePluralOrSingularText(
                      queuedForUpload.length,
                      'Submit Deal',
                      `Submit ${queuedForUpload.length} Deals`
                    )
                "
                :loading="formStatus === 'working'"
                :disabled="
                  formStatus === 'working' || (queuedForUpload.length === 0 && formStatus === 'idle')
                "
                @click="
                  formStatus === 'done' ? resetFileUpload() : submitFiles()
                "
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { reset, getNode } from '@formkit/core';

const runtimeConfig = useRuntimeConfig();

const { deckmatch } = runtimeConfig.public;


import { useSiteFunctionality } from '~/stores/siteFunctionality';
import { generatePluralOrSingularText } from '@/utils/stringUtils';

import { useDeckmatchUserStore } from '~/stores/deckmatchUser';


import { removeProtocol, addProtocolToUrl } from '@/utils/url';
import { generateErrorMessageFromApi } from '@/utils/error';

const acceptedFileTypes = ref([
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.apple.keynote',
  'application/x-iwork-keynote-sffkey',
]);

const siteFunctionalityStore = useSiteFunctionality();

const expanded = computed<boolean>(
  () => siteFunctionalityStore.getIsUploadModalOpen
);

const closeDialog = () => {
  siteFunctionalityStore.setIsUploadModalOpen(false);
  resetFileUpload();
};

const resetFileUpload = () => {
  queuedForUpload.value = [];
  parsedItems.value = [];
  failedItems.value = [];
  formStatus.value = 'idle';
};

const maxFileSize = ref(104_857_600); // 100MB · Arbitrary max file size of 100

const fileSizeFeedBack = ref<string[]>([]);

const urlTypes = ref([
  { label: 'Website', value: 'website' },
  { label: 'Pitch.com', value: 'pitch' },
  { label: 'Hubspot', value: 'hubspot' },
  { label: 'Google Drive', value: 'googleDrive' },
  { label: 'Google Docs', value: 'google' },
  { label: 'Dropbox', value: 'dropbox' },
]);
// Url and UrlType is not nested to make it easier to work with FormKit
type Status = 'failed' | 'waiting' | 'parsed' | 'parsing';
type Url = {
  status: Status;
  url: string;
  statusMessage?: string;
};
interface WebsiteLinkToBeParsed extends Url {
  type: 'website';
}
interface FileBehindUrlToBeParsed extends Url {
  type: 'docSend' | 'hubspot' | 'pitch' | 'dropbox' | 'google';
}
type LinksToBeParsed = WebsiteLinkToBeParsed | FileBehindUrlToBeParsed;
// File is nested due to limitations in expanding File type
type FileType = {
  type: 'file';
  status: Status;
  file: File;
  statusMessage?: string;
};

const queuedForUpload = ref<(LinksToBeParsed | FileType)[]>([]);
const parsedItems = ref<(LinksToBeParsed | FileType)[]>([]);
const failedItems = ref<(LinksToBeParsed | FileType)[]>([]);

const formStatus = ref<'idle' | 'working' | 'done'>('idle');

const updateQueuedForUpload = (newList: (LinksToBeParsed | FileType)[]) => {
  queuedForUpload.value = newList;
};

const submitFiles = async () => {
  const user = useDeckmatchUserStore().getUserInfo;

  if (!queuedForUpload?.value) return;

  const localCopy = queuedForUpload.value as (LinksToBeParsed | FileType)[];

  // Check if the form is filled out
  if (localCopy.length === 0) return;
  const authorization = await useSilentAuth()

    formStatus.value = 'working';
    for (let i = 0; i < localCopy.length; i++) {
      if (localCopy[i].type === 'website') {
        const item = localCopy[i] as WebsiteLinkToBeParsed;
        try {
          await $fetch('/api/deck/upload/url', {
            method: 'POST',
            body: JSON.stringify({
              url: item.url,
              investor_email: user.email,
            }),
            headers: {
              'Content-Type': 'application/json',
              authorization,
            },
          });
          localCopy[i].status = 'parsed';
        } catch (error) {
          localCopy[i] = {
            ...localCopy[i],
            status: 'failed',
            statusMessage: error.statusMessage,
          };
        }
        updateQueuedForUpload(localCopy);
      } else {
        // if the item is a file object or a file behind  a url
        const formData = new FormData();

        if (localCopy[i].type === 'file') {
          // for file object
          const item = localCopy[i] as FileType;
          formData.append('attachment_file', item.file);
        } else {
          // Catch all for files behind a url
          const item = localCopy[i] as FileBehindUrlToBeParsed;
          formData.append('attachment_url', item.url);
        }

        const url = `${deckmatch.baseUrl}/${deckmatch.version}`;
        const endpoint = 'workflow-management/trigger-deck-workflow/';

        const query = useDefaultQueries({ investor_email: user.email, origin: 'app'});
        try {
          await $fetch(`${url}/${endpoint}`, {
            method: 'POST',
            headers: {
              authorization,
            },
            body: formData,
            query,
          })
          
            localCopy[i].status = 'parsed';
          
        } catch (error) {
          const statusMessage =
            generateErrorMessageFromApi(
              error?.response?._data.error_code
            ) || 'Something went wrong. Please try again later.';
  
          localCopy[i] = {
            ...localCopy[i],
            status: 'failed',
            statusMessage,
          };
        }
      }
    }
  
  parsedItems.value = statusFilter(localCopy, 'parsed');
  failedItems.value = statusFilter(localCopy, 'failed');
  failedItems.value = failedItems.value.concat(
    statusFilter(localCopy, 'waiting')
  );
  failedItems.value = failedItems.value.concat(
    statusFilter(localCopy, 'parsing')
  );
  setTimeout(() => {
    updateQueuedForUpload([]);
    formStatus.value = 'done';

    if (failedItems.value.length === 0) {
      //
    }
  }, 1000);
};

const handleFileUpload = (files: File[]) => {
  fileSizeFeedBack.value = [];
  // filter out the files that are too large
  const [filesThatAreWithinLimit, filesThatAreTooLarge] =
    partitionFilesBySizeLimit(files, maxFileSize.value);

  if (filesThatAreTooLarge.length > 0) {
    const filesTooLarge = filesThatAreTooLarge.map((file) => file.name);
    //
    // toast error
    fileSizeFeedBack.value = filesTooLarge;
  }

  const listOfFiles = filesThatAreWithinLimit.map((file) => {
    return {
      type: 'file',
      status: 'waiting',
      file,
    };
  }) as FileType[];

  queuedForUpload.value = queuedForUpload.value.concat(listOfFiles);
};

const statusFilter = (list: (LinksToBeParsed | FileType)[], status: Status) => {
  return list.filter(
    (item: LinksToBeParsed | FileType) => item.status === status
  );
};

const handleAddUrl = (fields: any) => {
  const itemToAdd = [
    {
      status: 'waiting',
      url: addProtocolToUrl(removeProtocol(fields.url_to_be_added)),
      type: predicateUrlType(fields.url_to_be_added),
    },
  ] as LinksToBeParsed[];
  queuedForUpload.value = queuedForUpload.value.concat(itemToAdd);
  reset('myForm');
};

const removeItemFromQueue = (identifier: string) => {
  queuedForUpload.value = queuedForUpload.value.filter((item) => {
    if (item.type === 'file') {
      return item.file.name !== identifier;
    } else {
      return item.url !== identifier;
    }
  });
};

const setPredicatedUrlType = () => {
  const url_type_to_be_added = getNode('url_type_to_be_added');
  const url_to_be_added = getNode('url_to_be_added');

  if (!!url_type_to_be_added?.value || !url_to_be_added?.value) return;

  url_type_to_be_added?.input(
    predicateUrlType(url_to_be_added.value as string)
  );
};

const predicateUrlType = (url: string | undefined) => {
  // Assume website if no url is present — just to fail gracefully
  if (!url) return 'website';
  // Assume docSend if url includes the word docsend
  if (url.toLowerCase().includes('docsend')) {
    return 'docSend';
    
  } else if (url.toLowerCase().includes('pitch.com')) {
    return 'pitch';
  
  } else if (url.toLowerCase().includes('dropbox.com')) {
    return 'dropbox';
  
  }  else if (url.toLowerCase().includes('google.com')) {
    return 'google';
  
  }  else if (url.toLowerCase().includes('hubs.ly')) {
    return 'hubspot';
  
  } else {
    // All other urls are assumed to be websites
    return 'website';
  }
};
</script>
